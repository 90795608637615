<template>
  <span class="rv-tag">
    <Fa
      v-if="tagIcon"
      :icon="['fal', tagIcon]"
      class="mr-1"
    />
    {{ t(tag) }}
  </span>
</template>

<script setup lang="ts">
import type { TAGS } from '~/constants/rv'

const props = defineProps<{
  tag: TAGS
}>()

const { t } = useI18n()

const tagIcon = computed(() => {
  if (props.tag !== 'deliveryonly') {
    return
  }

  return 'truck-fast'
})
</script>

<style lang="scss" scoped>
.rv-tag {
  @include caption;

  border: none;
  color: getColor('primary-500');
  background-color: getColor('primary-50');
  padding: 0.2rem 0.5rem;
  font-size: 0.75rem;
}
</style>

<i18n lang="json">
{
  "en": {
    "featured": "Featured",
    "deliveryonly": "Delivery only"
  },
  "fr": {
    "featured": "En vedette",
    "deliveryonly": "Livraison seulement"
  }
}
</i18n>
