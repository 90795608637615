<template>
  <NuxtLink
    ref="card"
    v-element-visibility="scrolledIntoView"
    :to="urlToListing"
    :title="rv.RVName"
    :target="linkTarget"
    :class="['rv-card', { pulse: isStillLoading }]"
    :data-testid="`rv-card-${index}`"
    @click="rvClicked"
  >
    <div class="rv-images">
      <Transition name="fade">
        <div
          v-if="isStillLoading"
          key="loader"
          class="skel"
        />
        <LazyAppCarouselImages
          v-else
          key="content"
          :images="photos"
          :img-width="450"
          :img-height="300"
          resolution="medium"
          :preload="Boolean(photos.length && index === 0)"
          :eager-loading="index === 0 && isVisible"
          @click="onCarouselClick"
          @slide-changed="updateImageIndex"
        />
      </Transition>

      <Transition name="fade">
        <div v-if="!isLoading && badge">
          <LazyCardRvBadge :badge="badge" />
        </div>
      </Transition>

      <Transition name="fade">
        <LazyFavouritesButton
          v-if="!isStillLoading"
          icon-button-size="md"
          :rv-id="rv.Id!"
        />
      </Transition>
    </div>

    <div class="rv-info">
      <div class="rv-header">
        <Transition name="fade">
          <div
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <div
            v-else
            v-element-visibility="visibilityChanged"
            class="rv-title"
          >
            <div class="rv-name">
              {{ rv.RVName }}
            </div>
            <LazyZRating
              v-if="rv.AverageRating"
              :value="formatWithPrecision(rv.AverageRating)"
              bold
              class="d-flex align-items-center ml-2"
            />
          </div>
        </Transition>
      </div>

      <div class="rv-meta">
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel skel1 w-2/3"
          />
          <p
            v-else
            key="content"
          >
            {{ t('sleeps') }} {{ rv.Guests }} &bull; {{ t(rvLocalizedRVType) }}
          </p>
        </Transition>

        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel skel2 w-4/5"
          />
          <p
            v-else
            key="content"
          >
            {{ rv.City }}, {{ lookupStateToIsoCode(rv.State) }}
            <template v-if="showDistanceAway">
              ({{ t('away', { distance: distanceAway }) }})
            </template>
          </p>
        </Transition>
      </div>

      <div
        v-if="showDescription"
        class="rv-description"
      >
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <p
            v-else
            key="content"
          >
            {{ rvFormattedDescription }}
          </p>
        </Transition>
      </div>

      <div class="rv-price">
        <Transition name="fade">
          <div
            v-if="isStillLoading"
            key="loader"
            class="skel w-2/3"
          />
          <div
            v-else
            key="content"
            class="rv-prices"
          >
            <Fa
              v-if="rv.InstabookOwnerOptedIn"
              :icon="['fas', 'bolt']"
              class="instabook"
            />

            <span
              v-if="rv.DiscountPercent ?? 0 > 0"
              data-testid="discount-display"
              class="pre-discount-price"
            >{{
              priceDisplay({
                value: rv.PreDiscountedAverageNightlyPrice,
                countryCode: rv.Country as COUNTRY_SHORTS,
                showCurrencyCode: false,
                round: true,
                internationalPricing: true,
              })
            }}</span>

            <span
              data-testid="nightly-price"
              class="nightly-price"
            >
              {{
                priceDisplay({
                  value: Math.max(rv.DiscountedAverageNightlyPrice ?? 0, 0) || rv.DefaultPrice,
                  countryCode: rv.Country as COUNTRY_SHORTS,
                  showCurrencyCode: true,
                  round: true,
                  internationalPricing: true,
                })
              }}
              <span class="per">/{{ t('night') }}</span>
            </span>
          </div>
        </Transition>
      </div>

      <div
        v-if="hasTags"
        class="rv-tags"
      >
        <TransitionGroup name="fade">
          <span
            v-if="isStillLoading"
            key="loader"
            class="skel w-1/3"
          />
          <template v-else>
            <CardRvTag
              v-for="tag in rvAccolades.tags"
              :key="tag"
              :tag="tag"
            />
          </template>
        </TransitionGroup>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { vElementVisibility } from '@vueuse/components'
import type { LocationQuery } from 'vue-router'
import { trackListingClicked, trackListingSetViewed } from '~/lib/tracking'
import { searchParametersToSearchQuery } from '~/lib/search/parameters'
import getRvCategoryByType from '~/lib/getRvCategoryByType'
import type { Coordinate, MosaicImage, RvCard } from '~/types'
import type { COUNTRY_SHORTS, CURRENCIES } from '~/constants'
import type { SegmentTypes } from '~/types/api/rental-segment-types'
import { BADGES, TAGS } from '~/constants/rv'

type CtaType =
  'favorites'
  | 'featured'
  | 'map'
  | 'poi-rvs-best'
  | 'poi-rvs-delivery'
  | 'popular'
  | 'profile'
  | 'similar'
  | 'unpublished'

const props = withDefaults(defineProps<{
  index?: number
  rv: RvCard
  isLoading?: boolean
  cta?: CtaType
  searchRequestId: string
  visibilityThreshold?: number
  visibilityThrottle?: number
  destinationName?: string
  variant?: 'gallery' | 'map'
  trackVisiblity?: boolean
  showFeatured?: boolean
  showDescription?: boolean

  /**
   * Determines wether or not a card should load when it scrolls into view
   * (default), or imediately (useful for SEO).
   */
  noLazyLoad?: boolean
  pageSize?: number
  listPageNumber?: number
}>(), {
  index: 0,
  visibilityThreshold: 0.5,
  visibilityThrottle: 500,
  variant: 'gallery',
  pageSize: 10,
  listPageNumber: 1,
})

const emit = defineEmits<{
  'visible:rv': [{
    rv: RvCard
    cta?: CtaType
    requestId: string
  }]
  'scrolled-into-view': [index: number]
}>()

const { t, locale } = useI18n()
const { priceDisplay } = usePrice()
const { checkIfRvIsFavourited } = useFavouriteRVs()
const { routeBaseName } = useBaseName()
const route = useRoute()
const { isWebView } = usePlatform()
const { isMobile } = useDevice()
const { getImageUrl } = useImageUrl()
const { selectedCurrencyCode } = useCurrency()
const { $search, $captureError } = useNuxtApp()

const imageIndex = ref(0)

/**
 * Tracks wether or not the card is scrolled into view.
 */
const isVisible = ref(props.noLazyLoad)
const hasBeenScrolledIntoView = ref(false)
const hasBeenTracked = ref(false)

const isStillLoading = computed(() => {
  return props.isLoading || !isVisible.value
})

const isFeatured = computed(() => {
  return props.cta === 'featured'
})

const showDistance = computed(() => {
  return Boolean(
    (
      props.destinationName
      && props.destinationName !== props.rv.City
    )
    || $search.parameters.location?.city?.long_name,
  )
})

function getCoordinateValue(coordinate: (() => number) | number) {
  if (typeof coordinate === 'function') {
    return coordinate()
  }
  else {
    return coordinate
  }
}

const getDistanceAway = computed(() => {
  const lat = $search.parameters.location?.center?.lat
  const lng = $search.parameters.location?.center?.lng

  if (!lat || !lng || !props.rv.Latitude || !props.rv.Longitude) {
    return null
  }

  const coordinate1: Coordinate = {
    lat: getCoordinateValue(lat),
    lng: getCoordinateValue(lng),
  }
  const coordinate2: Coordinate = {
    lat: props.rv.Latitude,
    lng: props.rv.Longitude,
  }

  return distanceBetweenTwoPoints(coordinate1, coordinate2)
})

const rvMeasurementUnit = computed(() => {
  if (!props.rv.Country) return

  return countryCodeToDistanceUnit(props.rv.Country)
})

const rvFormattedDescription = computed(() => props.rv.Description ? props.rv.Description.replace(/"/g, '\\"') : '')

const rvLocalizedRVType = computed(() => props.rv.RVType ? `rvType.${props.rv.RVType}` : 'rvType.rvCottage')

const distanceAway = computed(() => {
  if (!getDistanceAway.value || !rvMeasurementUnit.value) return

  return formatDistance(getDistanceAway.value, rvMeasurementUnit.value, locale.value)
})

const showDistanceAway = computed(() => {
  return props.cta !== 'map' && showDistance.value && distanceAway.value
})

const urlToListing = computed(() => {
  const query = searchParametersToSearchQuery($search.parameters)
  return useRvUrl(props.rv.AliasName ?? '', query as LocationQuery)
})

const photos = computed(() => {
  if (!props.rv.Photos) {
    return []
  }

  const photos = props.rv.Photos.filter((photo) => photo.Path).map((photo) => {
    return {
      path: photo.Path,
      description: props.rv.RVName,
    } as MosaicImage
  })

  return photos
})

const openSameWindow = computed(() => {
  return Boolean(route.meta?.layout === 'minimal' || isWebView.value || isMobile)
})

const linkTarget = computed(() => {
  if (!openSameWindow.value) {
    return '_blank'
  }
  return null
})

const rvAccolades = computed(() => {
  const badges: BADGES[] = []
  const tags: TAGS[] = isFeatured.value ? [TAGS.Featured] : []

  if (props.rv.IsSuperHostActive) {
    badges.push(BADGES.Superhost)
  }

  if (props.rv.InstabookOwnerOptedIn) {
    badges.push(BADGES.InstantBook)
  }

  if (props.rv.IsDeliveryOnly) {
    badges.push(BADGES.DeliveryOnly)
    tags.push(TAGS.DeliveryOnly)
  }

  if (!badges.length) {
    return {
      tags,
    }
  }

  const [priorityBadge, ...otherBadges] = badges

  return {
    priorityBadge,
    otherBadges,
    tags,
  }
})

const badge = computed(() => {
  return rvAccolades.value?.priorityBadge
})

const hasTags = computed(() => {
  return Boolean(rvAccolades.value?.tags.length)
})

/**
 * Triggers when the card has scrolled into (or out of) view.
 */
function scrolledIntoView(visible: boolean) {
  /**
   * If we don't want to lazy load content, or when the card has scrolled
   * out of view, or when it has already been triggered, exit early.
   *
   * Otherwise fire the 'scrolled-into-view' event and track it locally.
   */
  if (props.noLazyLoad || !visible || hasBeenScrolledIntoView.value) return

  emit('scrolled-into-view', props.index)

  isVisible.value = visible
  hasBeenScrolledIntoView.value = true
}

function onCarouselClick(e: PointerEvent) {
  // Skip track at photos navigation
  if (!(e.target instanceof Element)) {
    return
  }

  if (e.target.closest('.glide__bullet') || e.target.closest('.glide__arrow')) {
    e.stopPropagation()
    e.preventDefault()
  }
}

function updateImageIndex(index: number) {
  imageIndex.value = index
}

const cardEl = useTemplateRef('card')
function trackClick() {
  const parent = cardEl.value?.$el.parentElement

  if (!cardEl.value || !parent) {
    return $captureError(new Error('trackClick called, but template ref or parent element do not exist'), {
      cardRef: cardEl.value,
    })
  }

  const listIndex = [...parent.children].findIndex((el) => el === cardEl.value)
  const columnCount = window.getComputedStyle(parent).getPropertyValue('grid-template-columns').split(' ').length
  const listRowNumber = Math.floor(listIndex / columnCount) + 1
  const listColumnNumber = (listIndex % columnCount) + 1

  trackListingClicked({
    cta: props.cta || routeBaseName.value,
    distanceShown: showDistance.value,
    hasDiscount: (props.rv?.DiscountPercent ?? 0) > 0,
    hasFlexibleDates: undefined,
    isMapOpen: document.getElementsByClassName('map-wrapper').length > 0,
    isShortStay: props.rv.IsShortStay ?? false,
    isSuperhost: props.rv.IsSuperHostActive ?? false,
    listCardVariant: props.variant,
    listColumnNumber,
    listRowNumber,
    pageSource: routeBaseName.value,
    photoPath: photos.value[imageIndex.value]?.path || '',
    photoPosition: imageIndex.value + 1,
    rv: getRvTrackingProperities(),
    rvType: props.rv.RVType!,
  })
}

function rvClicked() {
  if (isStillLoading.value) {
    return
  }

  trackClick()
  setRvDetailsPageReferrerAndCta()
}

function visibilityChanged(visible: boolean) {
  if (!visible || hasBeenTracked.value) return

  hasBeenTracked.value = true

  if (!props.trackVisiblity) {
    emit('visible:rv', {
      rv: props.rv,
      cta: props.cta,
      requestId: props.searchRequestId,
    })
    return
  }

  const rvPath = generateRvPath(props.rv.AliasName ?? '')
  trackListingSetViewed({
    params: {
      cta: props.cta,
      listingPageNumber: props.listPageNumber,
      listingPageSize: props.pageSize,
      listings: [getRvTrackingProperities()],
      pageSource: routeBaseName.value,
    },
    additionalParameters: {
      rvUrlListings: [{
        rvUrl: useAbsoluteUrl(rvPath ?? ''),
      }],
    },
  })
}

type Listings = SegmentTypes['ListingSetViewed [v5]']['listings']
function getRvTrackingProperities(): Listings[number] {
  return {
    heroImage: getImageUrl({
      path: props.rv.Photos?.[0]?.Path,
      resolution: 'medium',
      quality: 'medium',
    }) ?? '',
    isFavourite: Boolean(checkIfRvIsFavourited(props.rv.Id!)),
    nightlyRate: Number.parseFloat(priceDisplay({
      value: Math.max(props.rv?.DiscountedAverageNightlyPrice ?? 0, 0) || props.rv.DefaultPrice,
      countryCode: props.rv.Country as COUNTRY_SHORTS,
      showCurrencyCode: false,
      round: true,
      internationalPricing: true,
    }).replace('$', '')),
    smartNightlyRate: props.rv.SmartPricingPercentage ?? 0 > 0 ? Math.round((props.rv?.DiscountedAverageNightlyPrice ?? 0) || (props.rv?.DefaultPrice ?? 0)) : undefined,
    rvName: props.rv.RVName ?? '',
    rvId: props.rv.Id!,
    currency: selectedCurrencyCode.value as CURRENCIES,
    listingType: props.rv.RVType!,
    distanceUnit: rvMeasurementUnit.value ?? 'km',
    hasDelivery: props.rv.HasDelivery!,
    listingCountry: props.rv.Country as COUNTRY_SHORTS,
    listingRegion: props.rv.State ?? '',
    listingCity: props.rv.City ?? '',
    isFeatured: props.rv.IsFeatured!,
    isInstantBook: props.rv.InstabookOwnerOptedIn!,
    numReviews: props.rv.NumberOfReview ?? 0,
    reviewsShown: (props.rv.NumberOfReview ?? 0) > 0,
    rentalType: getRvCategoryByType(props.rv.RVType) || 'Unknown',
    starRating: props.rv.AverageRating ?? 0,
    listingSleepingSpots: props.rv.Guests ?? 0,
  }
}

function setRvDetailsPageReferrerAndCta() {
  if (props.cta) {
    // Add rvId to the key, so it's unique to each RV.
    // Localstorage was used instead of sessionStorage, as sessionStorage didn't work when opening certain links in a new tab
    window.localStorage.setItem(`rvDetailsPageCta_${props.rv.Id}`, props.cta)
  }

  // Store the current route in localStorage. The RV Details page is opened in a new tab, so context does not contain the route
  window.localStorage.setItem(`rvDetailsPageCtaReferrer_${props.rv.Id}`, routeBaseName.value || '')
}
</script>

<style lang="scss" scoped>
.pulse {
  cursor: wait;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }
}

.skel {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
  border-radius: 0.25rem;
  background-color: getColor('gray-100');
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: calc(100% * (1 / 3));
}

.w-2\/3 {
  width: calc(100% * (2 / 3));
}

.w-4\/5 {
  width: calc(100% * (4 / 5));
}

.rv-images {
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  padding-bottom: calc(2 / 3 * 100%);
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 0.75rem;

  :deep(.glide__slide) {
    border-radius: 1rem;
    overflow: hidden;
  }
}

.zbadge {

  &.superhost,
  &.instantbook {
    border-radius: 1rem;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 2;
    font-size: 0.75rem;
    padding: 0.1875rem 0.625rem;

    svg {
      font-size: 0.875rem;
      vertical-align: -0.1875rem;
    }
  }

  &.instantbook {
    color: getColor('primary-500');
  }

  &.superhost {
    color: getColor('highlight-500');
  }
}

.rv-card {
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: getColor('primary-500');

  &:hover {
    text-decoration: none;
  }
}

.rv-image {
  border-radius: 1rem;
  overflow: hidden;

  :deep(img) {
    border-radius: 1rem;
  }
}

.rv-header {
  position: relative;
  height: 1.5rem;
  margin-bottom: 0.25rem;
}

.rv-title {
  display: flex;
  align-items: flex-start;
}

.badge.rare-find {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  border: none;
  color: getColor('highlight-500');
  background-color: getColor('highlight-50');
  padding: 0.2rem 0.5rem;
  @include semi-bold;
  font-size: 0.75rem;
}

.rv-meta {
  @include caption;

  position: relative;
  height: 40px;
  margin-bottom: 0.75rem;

  color: getColor('primary-350');
}

.rv-meta,
.rv-meta-guests,
.rv-meta-city,
.rv-price {
  position: relative;
  height: 40px;

  p {
    margin: 0;
  }
}

.rv-meta-city p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rv-price {
  height: 1.5rem;
  margin-bottom: 0.75rem;
}

.rv-tags {
  margin-top: 1rem;
  position: relative;
  height: 1.625rem;
  display: flex;
  gap: 0.5rem;
}

.rv-info {
  position: relative;
  margin-top: 0.75rem;
  color: getColor('primary-500');

  &:hover {
    text-decoration: none;
  }

  p {
    margin: 0;
  }
}

.rv-name {
  @include strong-1;
  position: relative;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.5rem;
  margin: 0;
}

.nightly-price {
  @include semi-bold;
  display: flex;
  font-size: 1rem;
  align-items: center;

  @include media-max-size(large) {
    @include rv-card-price;
  }

  .night {
    font-size: 0.75rem;
    text-transform: lowercase;
  }
}

.pre-discount-price {
  @include body-1;
  margin-right: 0.5rem;

  text-decoration: line-through;
  color: getColor('primary-350');
}

@include media-max-size(medium) {

  .nightly-price,
  .pre-discount-price {
    font-size: 1rem;
  }
}

.instabook {
  color: getColor('highlight-500');
  margin-right: 0.2rem;
}

:deep(.favourite-button) {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;

  >.zbtn {
    background-color: rgba(255, 255, 255, 0.8) !important;

    &:hover {
      background-color: #fff !important;
    }

    svg {
      color: getColor('primary-500');
      font-size: 1rem;
    }

    @include media-min-size(medium) {
      width: 1.5rem !important;
      height: 1.5rem !important;

      svg {
        font-size: 0.75rem;
      }
    }
  }
}

.rv-prices {
  display: flex;
  align-items: center;
}

.per {
  font-size: 0.75rem;
  font-weight: normal;

  @include media-max-size(medium) {
    @include rv-card-price;
  }
}

.city {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rv-description {
  position: relative;
  max-width: 100%;
  height: 3.75rem;
  margin-bottom: 0.75rem;

  > p {
    margin: 0;
    color: getColor('primary-500');
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 0.875rem;
    @include regular-weight;
    line-height: 1.25rem;
  }
}

.skel1,
.skel2 {
  height: 20px;
}

.skel2 {
  top: 20px;
}
</style>

<i18n lang="json">
{
  "en": {
    "away": "{distance} away",
    "night": "night",
    "sleeps": "Sleeps",
    "featured": "Featured",
    "rareFind": "Rare Find",
    "superhost": "Superhost",
    "instantbook": "Instant Book"
  },
  "fr": {
    "away": "à {distance}",
    "night": "nuit",
    "sleeps": "Couche",
    "featured": "En vedette",
    "rareFind": "Trouvaille rare",
    "superhost": "Superhôtes",
    "instantbook": "Réservation Instantanée"
  }
}
</i18n>
